import { useState } from "react";
import { IntroPage } from "../Styles/intro.styles";
import { ModuleCubeContainer,AppTable,RightButton,LeftButton,SlideDownButton } from "./module.styles";
// import CheckerBoard from "./Rowz/CheckerBoard/CheckerBoard";
// import CheckerBoard from "../Rowz/CheckerBoard/CheckerBoard";
// import TicTacToe from "../TicTacToe/tictactoe.component";
// import Graph from "../../the-difference-engine/Graphs/graph.component";
import { largeLeftArrow,largeRightArrow } from "../Styles/large-svg";
import { Introh2 } from "../Styles/intro.styles";
// import { QuietButton } from "../Form/forminput.styles";
// import { gitBash,link,info,code,Wrapper} from "../Styles/small-svg";
import SpinCube from "../SpinCube/cube.component";
// import diffengine from './Photos/diff-engine.png'
import diffengine from './Photos/HomePage3.png'
import Rowz from './Photos/rowz3.png'
import jupyter from './Photos/jupyter2.png'
import MM3d from './Photos/mm3d1.png'
import Cardz from './Photos/Cardz1.png'

// const reactLogo = {
//     width:'150px',
//     marginTop:'20px'
// }

// const linkStyle = {
//     position:'absolute',
//     bottom:'20px',
//     left:'45%',
//     width:'30px',
// }


const ReactModules = ({slideDown}) => {


    const [state,setState] = useState({
        xRotation:0,
        yRotation:0,
    })

    const projectArr = [[diffengine,'https://math.madmodels3d.com/'],[Rowz,'http://games.madmodels3d.com/'],[jupyter,'https://jupyter.madmodels3d.com/'],[Cardz,'http://cardz.madmodels3d.com/']]

    const mappedProjects = projectArr.map((el,i) => {
        return (
            <AppTable key={i} href={el[1]} target="_blank"><img src={el[0]} /></AppTable>
        )
    })
    
    return (
        <IntroPage>
            {/* <h2>React Apps</h2> */}
 
            {/* <img style={reactLogo} src='https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg' /> */}

            {/* <p>
            Here are some of the recent React modules I've worked on.
            </p> */}
               
            {/* <ModulesLayout> */}
                {/* {mappedProjects} */}
                {/* <AppTable> */}

                    {/* <a 
                        href="http://games.madmodels3d.com"
                        target="_blank"
                    ><img src={Rowz} style={{position:'relative',marginLeft:'auto',marginTop:'30px',padding:'0px',width:'100%'}}/></a>
            
                    <a
                        style={linkStyle}
                        href="https://github.com/Nefari0/Games"
                        target="_blank"
                    ><Wrapper>{gitBash()}</Wrapper></a> */}

                    

                   
                {/* </AppTable> */}
                
                <Introh2>Hi, I'm Chris. Nice to meet you.</Introh2>
                <p>
                After 15 years of tinkering with game developement, I discovered coding. I now build applications using the ReactJS/NodeJS framework, and make powerful numerical and data predictions using Python.
                </p>
                <h1>My brand</h1>
                <ModuleCubeContainer>
                    <LeftButton onClick={() => setState({...state,yRotation:state.yRotation+90})}>{largeLeftArrow()}</LeftButton>
                    <SpinCube state={state} mappedProjects={mappedProjects}/>
                    <RightButton onClick={() => setState({...state,yRotation:state.yRotation-90})}>{largeRightArrow()}</RightButton>
                <SlideDownButton
                    onClick={slideDown}
                    // style={{
                    //     position:'absolute',
                    //     top:'100%',
                    //     width:'200px',
                    //     height:'25px',
                    //     zIndex:'1000000'
                    // }}
                >
                    view more projects
                </SlideDownButton>
                </ModuleCubeContainer>



                

                {/* <div style={{position:'absolute',top:'300px'}}>
                </div> */}

                {/* <AppTable>
                   
                    <GraphAdapter>
                    
                        <a
                            href="http://math.madmodels3d.com"
                            target="_blank"
                        >    
                            <img src={diffengine}/>
                        </a>
                    </GraphAdapter>
                    <div style={{width:'20px',overflow:'hidden'}}>

                    <a
                        style={linkStyle}
                        href="https://github.com/Nefari0/difference-engine-app.git"
                        target="_blank"
                    ><Wrapper>{gitBash()}</Wrapper></a>
                    </div>
                </AppTable> */}

                {/* <AppTable>
                    <h1>Matrices</h1>
                    <TicTacToeAddapter>
                        <TicTacToe />
                    </TicTacToeAddapter>
                    <a
                        style={linkStyle}
                        href="https://github.com/Nefari0/Games"
                        target="_blank"
                    >{gitBash()}</a>
                </AppTable> */}

            {/* </ModulesLayout> */}
            
        </IntroPage>
    )
}

export default ReactModules