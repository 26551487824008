import styled from "styled-components"
import { palette } from "../Styles/colors"

const { white,deepBlue } = palette

export const NoticeContainer = styled.div`
    width:80vw;
    min-height:200px;
    background-color: ${white};
    // background-color:blue;
    // position:absolute;
    // position:fixed;
    position:relative;
    top:0px;
    margin:auto;
    border: solid 2px ${deepBlue};
    border-radius:10px;
    box-shadow: 0px 5px 20px -7px #000000;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-end;
    z-index:4;
    overflow:hidden;
    padding-bottom:10px;
`