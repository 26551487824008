import styled from "styled-components";
import { palette } from "../Styles/colors";
import { device } from "../Styles/queries";

const { deepBlue,white } = palette
const { desktop,tablet,tabletS,mobileL,mobileS,mobileM } = device

export const Hero = styled.section`
    display: flex;
    flex-direction: column;
    // flex-wrap: nowrap;
    // justify-content: flex-start;
    align-items: center;
    // align-content: stretch;

    a {
        // z-index:1000000;
        margin:20px;
        // position:absolute;
    }

    h1 {
        font-size:36px;
        text-align:center;

        @media ${tabletS} {
            // width:300px;
            font-size:30px;
        }

        @media ${mobileL} {
            width:300px;
            margin-top:-80px;
            // font-size:30px;
        }
    }

    p {
        font-size:20px;

        @media ${tabletS} {
            width:500px;
            text-align:center;
            // font-size:30px;
        }

        @media ${mobileL} {
            width:300px;
            // font-size:30px;
        }
    }


`

export const Portrait = styled.div`
    border-radius:50%;
    height:170px;
    width:170px;
    overflow:hidden;
    position:relative;
    margin-top:40px;
    border: solid 2px ${deepBlue};

    img {
        position:absolute;
        top:-10px;
        left:0px;
        width:180px;
    }
`

export const IconRow = styled.div`
    display:flex;
    z-index:1;

    a {
        -webkit-transform: scale(${.7});
        -ms-transform: scale(${.7});
        transform: scale(${.7});
        cursor: pointer;
    }

    @media ${tabletS} {
        a {
            -webkit-transform: scale(${.5});
            -ms-transform: scale(${.5});
            transform: scale(${.5});
        }
    }

    @media ${mobileM} {
        a {
            margin:0px;
        }
    }
`

// export const ContactPage = styled.form`
//     position:fixed;
//     top:2%;
//     max-width:600px;
//     // min-width:280px;
//     // height:600px;
//     // height:200px;
//     background-color:${white};
//     z-index:100000;
//     box-shadow: 0px 5px 20px -7px #000000;
//     border-radius:10px;
//     // border: solid 2px ${deepBlue};
//     overflow:hidden;
    
//     margin:auto;
//     min-height: 300px;

//     // width: 570px;
//     padding:32px;
//     background-color: ${white};
//     display: flex;
//     flex-direction:column;
//     flex-wrap: wrap;
//     align-items: center;
//     // z-index:0;   

//     @media (max-height:700px) {
//         position:absolute;
//         top:-80px;
//     }
// `

// export const NoticeContainer = styled.div`
//     width:80vw;
//     min-height:200px;
//     background-color: ${white};
//     position:absolute;
//     top:0px;
//     border: solid 2px ${deepBlue};
//     border-radius:10px;
//     box-shadow: 0px 5px 20px -7px #000000;
//     display:flex;
//     flex-direction:column;
//     align-items:center;
// `

// - Font sizes (px)
// 10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98
